@font-face { 
  font-family: 'Sacramento-Regular'; 
  src: url('./fonts/Sacramento-Regular.ttf'); 
} 

.Menu {
  text-align: center;
  background-color: #e8f21f;
  min-height: 100vh;

}

.Menu-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Menu-logo {
    animation: Menu-logo-spin infinite 10s linear;
  }
}

.Menu-header {
  /*background-color: #e8f21f;*/
  /*min-height: 100vh;*/
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  /*justify-content: center;*/
  font-size: calc(10px + 2vmin);
  color: black;
}

.Menu-link {
  color: #61dafb;
}

@keyframes Menu-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h3 {
  text-align: center;
}

.menuColumn {
  float: middle;
  width: 35%;
  display: inline;
}

.menuColumnPadding {
  float: middle;
  width: 15%;
  display: inline;
}

.menuColumnMiddle {
  text-align: center;
  width: 100%;
}

/* Clear floats after the columns */
/*.row:after {
  content: "";
  display: table;
  clear: both;
} */
li {
  vertical-align: middle;
  text-align: center;
  margin: 0 auto;
  padding: none;
}
 
ul {
  list-style-type:none;
  vertical-align: middle;
  text-align: center;
  margin: 0 auto;
  padding: none;
}

p {
  vertical-align: middle;
  font-size: calc(6px + 2vmin);
  margin: 0px;
  padding: 0px;
  /*display: inline;*/
  margin-bottom: 0 !important;
}

.menuDescription {
  font-size: calc(2px + 2vmin);
  color: #838584;
  /*margin: 0 auto;*/
  /*padding: none;*/
  /*font-style: italic;*/
  margin-bottom: 20px !important;
  padding-left: 40px;
  padding-right: 40px;
  font-family: 'Open Sans Condensed';
  /*font-weight: 300;*/
  font-size: 3vh;
}

.menuItem {
  font-family: 'Open Sans Condensed';
  font-size: 4vh;
  font-weight: bold;
}

.menuHeader {
  font-family: 'Sacramento-Regular';
  /*font-weight: bold;*/
  font-size: 7vh;
  padding-bottom: 20px
}
a {
  color:#36cfa1 !important;
}
