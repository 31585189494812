@font-face { 
  font-family: 'Sacramento-Regular'; 
  src: url('./fonts/Sacramento-Regular.ttf'); 
} 

@font-face { 
  font-family: 'Open Sans Condensed'; 
  src: url('./fonts/OpenSansCondensed-Light.ttf');
} 

.App {
  text-align: center;
    font-family: 'Sacramento-Regular';
  /*font-weight: bold;*/
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s linear;
  }
}

.App-header {
  background-color: #e8f21f;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*font-size: calc(10px + 2vmin);*/
  color: black;
}

.App-link {
  color: #61dafb;
}

.siteTitle {
  font-size: 10vh;
}

.siteSubtitle {
  font-size: 5vh;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
